import React, { useState } from 'react';
import ProductCard from './ProductCard';
import { useInView } from 'react-intersection-observer';

export const NewProductCard = ({
  product,
  index,
  loading,
  cart,
  groupedProducts,
  recommendation_id,
  onClickCallback,
  rest
}) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  return (
    <div ref={ref}>
      <ProductCard
        onClickCallback={onClickCallback}
        key={index + ':' + product.articleNumber}
        product={product}
        style={{ opacity: loading ? 0.5 : 1 }}
        className="product-card"
        cart={cart}
        groupedProducts={groupedProducts}
        recommendation_id={recommendation_id}
        showed={showed}
        {...rest}
      />
    </div>
  );
};
