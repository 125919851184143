import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import SubMenuWrapper from './SubMenuWrapper';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  width: calc(100% - 17rem);
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 1.2rem 1.2rem 1rem 1.2rem;
    display: inline-block;
    color: ${theme('colors.black')};
    text-decoration: none;
    text-transform: uppercase;
    transition: all ease 0.2s;
    font-family: ${theme('fonts.body')};
    font-size: 16px;
    letter-spacing: 1px;
    &:hover {
      color: ${theme('colors.grey')};
      text-decoration: none;
    }
    &.active {
      color: #000;
      font-weight: bold;
    }
  }
`;

const Cover = styled('div')`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <>
          <Wrapper onMouseLeave={props.clearActiveCategories}>
            <ul>
              {queryData && queryData.categories
                ? queryData.categories.map(cat => (
                    <MainMenuItem
                      key={cat.id}
                      // When mousing over a menu item, set it as the active nav
                      onMouseEnter={() => props.setActiveCategory(cat)}
                    >
                      <CategoryLink
                        // When following a category link, close the menu
                        onClick={props.clearActiveCategories}
                        category={cat}
                      >
                        {cat.name}
                      </CategoryLink>

                      {props.activeCategories.length > 0 &&
                      props.activeCategories[0].id === cat.id ? (
                        <SubMenuWrapper
                          pose={
                            props.activeCategories.length > 0 &&
                            props.activeCategories[0].subcategories.length > 0
                              ? 'open'
                              : 'closed'
                          }
                          activeCategory={props.activeCategories[0]}
                          closeNav={props.clearActiveCategories}
                        />
                      ) : null}
                    </MainMenuItem>
                  ))
                : null}
            </ul>
          </Wrapper>
          {props.activeCategories.length > 0 ? <Cover /> : null}
        </>
      )}
    </UIMenuContainer>
  );
};

export default MenuContainer;
