import React, { Component } from 'react';
import styled, { keyframes } from 'react-emotion';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FavoriteListConsumer } from './FavoriteListProvider';

import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons';
library.add(faShoppingCart, faHeart);

const circle = keyframes`
  from, 0% to {
    transform: translateY(-50%) translateX(-50%) scale(0);
    
  }

  60% {
    transform: translateY(-50%) translateX(-50%) scale(.5);
    opacity:1;
    box-shadow:inset 0px 0px 10px 10px lightgrey;
  }

  100% {
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity:0;
  }
`;

const heartPop = keyframes`
  from, 20% to {
    transform: scale(1);
  }

  20%{
    transform: scale(.9);
  }

  70% {
    transform:scale(1.1);
  }

  100% {
    transform:scale(1);
  }
`;

const IconContainer = styled('div')`
  display: inline-block;
  text-align: right;
  position: relative;
  align-items: right;
  justify-content: flex-end;
  margin-bottom: -2.25rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  cursor: pointer;
  svg {
    width: 18px;
  }
  &.active {
    &::before {
      animation: ${circle} 0.6s ease forwards;
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      box-shadow: inset 0 0 2px 2px lightgrey;
      width: 40px;
      height: 40px;
      top: 50%;
      left: calc(100% - 0.5rem - 1px);
      transform: translateY(-50%) translateX(-50%) scale(0);
      border-radius: 50%;
      opacity: 0;
    }
  }
`;

export const FavoriteIcon = styled(FontAwesomeIcon)`
  font-size: 1.1rem;
  z-index: 50;
  color: #333;
  transition: 0.3s 0s;
  position: relative;
  color: transparent;
  stroke-width: 20px;
  stroke: black !important;
  z-index: 1;

  &.red {
    color: black;
    stroke: black !important;
    transition: 0.3s 0.3s;
    animation: ${heartPop} 0.6s ease forwards;
  }
`;

class AddFavoriteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faKey: 'fal',
      active: 'black'
    };
  }

  fillStar(id) {
    this.setState({ faKey: 'fas', active: 'red' });
  }
  unFillStar(id) {
    this.setState({ faKey: 'fal', active: 'black' });
  }
  handleClick = e => {
    e.preventDefault();
    if (this.props.isInList(this.props.product.id)) {
      this.unFillStar();
      this.props.removeFromList(this.props.product.id);
    } else {
      this.fillStar();
      this.props.addToList(this.props.product);
      if (typeof window.mtr !== 'undefined') {
        mtr.goal('Add to Favorites');
      }
    }
  };

  componentDidMount() {
    const { product, isInList } = this.props;
    if (product) {
      let id = product.id;
      if (isInList(id)) {
        this.fillStar(id);
      } else {
        this.unFillStar(id);
      }
    }
  }

  render() {
    return (
      <IconContainer
        className={
          'favorite-icon ' +
            (this.props.isInList(this.props.product.id) && 'active') ||
          'inactive'
        }
      >
        <FavoriteIcon
          onClick={this.handleClick}
          icon={'heart'}
          className={
            (this.props.isInList(this.props.product.id) && 'red') || 'black'
          }
        />
      </IconContainer>
    );
  }
}

const AddFavoriteButton = props => {
  const { product } = props;
  return (
    <FavoriteListConsumer>
      {({ favoriteList, addToList, isInList, removeFromList }) => (
        <AddFavoriteItem
          favoriteList={favoriteList}
          addToList={addToList}
          product={product}
          isInList={isInList}
          removeFromList={removeFromList}
        />
      )}
    </FavoriteListConsumer>
  );
};

export default AddFavoriteButton;
