import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
library.add(faSearch);

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
`;

const Button = styled('button')`
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme('colors.black')};
  margin-left: 1rem;
  margin-right: 0.5rem;
  :hover {
    color: ${({ theme }) => theme.colors.black};
    svg {
      use {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }
  ${theme('below.lg')} {
    margin: 0;
    padding: 10px 5px;
  }
`;

function SearchButton(props) {
  const { setSearchOpen, searchOpen, ...rest } = props;
  return (
    <Button onClick={() => setSearchOpen(!searchOpen)} {...rest}>
      <Icon icon={['fal', 'search']} />
    </Button>
  );
}

export default SearchButton;
