import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import theme from '@jetshop/ui/utils/theme';
import ProductLink from '@jetshop/ui/ProductLink';
import React from 'react';
import styled, { css } from 'react-emotion';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';

export const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 10px;
  padding: 0;
  transition: all linear 0.3s;
  background: ${theme('colors.background')};
  padding: 5px 0 9px;

  > :first-child {
    flex: 1 1 25%;
  }
  > :last-child {
    flex: 1 1 70%;
    padding: 0.5rem;
  }
`;

export const ProductName = styled('div')`
  color: ${theme('colors.black')};
  font-size: 12px;
  line-height: 1.15;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
  h2 {
    color: ${theme('colors.black')};
    font-size: 12px;
    line-height: 1.15;
    text-transform: uppercase;
    font-family: ${theme('fonts.body')};
  }
  a {
    color: ${theme('colors.black')};
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

export const ItemDetails = styled('div')`
  grid-area: topleft;
`;

export const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  font-size: 13px;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: right;
  text-transform: uppercase;
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: transparent;
    color: black;
    height: 25px;
    line-height: 25px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
    border: 1px solid black;
    padding: 0 3px;
    height: 25px;
    line-height: 25px;
    width: 35px;
    text-align: center;
    font-size: 13px;
  }
`;

export const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #000;
  }
  svg {
    height: 13px;
    width: 13px;
    g,
    use {
      fill: #000;
    }
  }
`;

const CartItem = ({ item, hideTarget, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);
  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  return (
    <Wrapper className={className}>
      <div>
        <ProductLink product={item.product} onClick={hideTarget}>
          {item.product.images.length > 0 && (
            <Image
              aspect="1:1"
              sizes="5rem"
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
            />
          )}
        </ProductLink>
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <ProductLink product={item.product} onClick={hideTarget}>
              <h2>{item.product.name}</h2>
            </ProductLink>
            {isVariant && (
              <span
                className={css`
                  margin-top: 0.5rem;
                  letter-spacing: 0.5px;
                  color: #181818;
                `}
                style={{ display: 'block', textTransform: 'capitalize' }}
              >
                {item.variantOptionNames[0] + ': '}
                {variantValues.join(', ')}
              </span>
            )}
          </ProductName>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <AdjustQty>
          <button
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span>{item.quantity}</span>
          <button onClick={() => incrementQuantity({ itemId: item.id })}>
            +
          </button>
        </AdjustQty>

        <LinePrice price={item.total} />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
