import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import theme from '@jetshop/ui/utils/theme';

import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';

import { Wrapper, ProductDetail, ProductName } from '../Cart/CartItem';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';

library.add(faAngleRight, faHeart);

const FavoriteDetail = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  align-content: center;
  transition: all ease 0.3s;
  ${ProductName} {
    margin-bottom: 5px;
    font-weight: 300;
    padding-right: 10px;
  }
`;

const ItemDetails = styled('div')`
  width: 100%;
`;

const FavoritePrice = styled(Price)`
  font-weight: 300;
  font-size: 12px;
  width: 100%;
`;

const RemoveItem = styled('div')`
  margin-bottom: 5px;
  margin-left: auto;
  text-align: right;
  button {
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
  }
  svg {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 4px;
    right: 6px;
    g,
    use {
      fill: #000;
    }
  }
`;

const RightArrow = styled(FontAwesomeIcon)`
  color: ${theme('colors.warmGrey')};
  font-size: 10px;
`;

const FavoriteItem = props => {
  const { item, removeFromList, onClick } = props;
  return (
    <Wrapper>
      <div style={{ marginLeft: '.5rem' }}>
        <Link onClick={onClick} to={item.product.primaryRoute.path}>
          {item.product.images.length > 0 && (
            <Image
              aspect="1:1"
              src={item.product.images[0].url}
              alt={item.product.name}
            />
          )}
        </Link>
      </div>
      <FavoriteDetail>
        <RemoveItem>
          <button onClick={() => removeFromList(item.product.id)}>
            <Cross />
          </button>
        </RemoveItem>

        <ItemDetails>
          <ProductName>
            <h2>
              <Link onClick={onClick} to={item.product.primaryRoute.path}>
                {item.product.name}
              </Link>
            </h2>
          </ProductName>
          <FavoritePrice price={item.product.price} />
        </ItemDetails>
      </FavoriteDetail>
    </Wrapper>
  );
};

export default FavoriteItem;
