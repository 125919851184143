import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

const Badge = styled('div')`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100%;
  background: #e43b3b;
  color: white;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 1;

  &.product-page {
    right: auto;
    bottom: auto;
    left: 120px;
    top: 10px;
    z-index: 2;
    ${({ theme }) => theme.below.lg} {
      left: 10px;
    }
  }
`;

export const SaleBadge = ({ price, previousPrice, productPage }) => {
  if (previousPrice.incVat > price.incVat) {
    const salePercent = Math.round(
      (1 - price.incVat / previousPrice.incVat) * 100
    );
    return (
      <Badge
        className={productPage ? 'product-page' : null}
      >{`${salePercent}%`}</Badge>
    );
  }
  return null;
};
