import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useRef, useEffect } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import UspWrapperTopBarQuery from './UspWrapperTopBarQuery.gql';
import { ReactComponent as Check } from '../../../svg/Check.svg';
import { useLipscoreProductData } from '../../ProductPage/Lipscore/useLipscoreProductData';
import { LipscoreProductRating } from '../../ProductPage/Lipscore/LipscoreProductRating';
import theme from '@jetshop/ui/utils/theme';

const UspInnerWrapper = styled('div')`
  width: 100%;
  display: flex;
  ${({ theme }) => theme.below.lg} {
    margin: 0;
    padding-top: 0;
  }
  ${({ theme }) => theme.below.md} {
    width: 100%;
    justify-content: center;
  }
  ul {
    justify-content: space-between;
    display: flex;
    ${({ theme }) => theme.below.lg} {
      justify-content: center;
      display: flex;
    }
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-family: ${({ theme }) => theme.fonts.body};
    ${({ theme }) => theme.below.md} {
      font-size: 9px;
      padding: 0 5px;
      letter-spacing: 1px;
    }
    span {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    svg {
      display: block;
      margin-right: 5px;
      width: 11px;
      use {
        fill: white;
      }
      ${({ theme }) => theme.below.lg} {
        display: none;
      }
    }
  }

  .star-rating {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }
  .rating-text {
    font-size: 11.2px !important;
    margin-top: 0 !important;
    font-family: ${theme('fonts.body')};
    color: #fff !important;
    margin-right: 6px;
    margin-bottom: 1px;

    span {
      display: none;
    }
  }
  .stars {
    svg {
      padding-bottom: 2px;
      width: 12px;
    }
  }

  ${({ theme }) => theme.below.lg} {
    .rating-text {
      font-size: 9px !important;
      margin-bottom: 0;
    }
    .stars {
      font-size: 9px !important;
      svg {
        padding-bottom: 3px !important;
        width: 11px;
      }
    }
  }
`;

const Text = ({ content }) => {
  const txtEl = useRef();
  const iconEl = useRef();
  useEffect(() => {
    var paragraphs = txtEl.current.getElementsByTagName('li');
    for (var i = 0; i < paragraphs.length; i++) {
      var clone = iconEl.current.cloneNode(true);
      paragraphs[i].prepend(clone);
    }
    iconEl.current.remove();
  }, [content]);
  return (
    <>
      <div
        className="usp-text"
        ref={txtEl}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
      <span style={{ display: 'none' }} ref={iconEl}>
        <Check />
      </span>
    </>
  );
};

const UspWrapperTopBar = () => {
  const { selectedChannel } = useContext(ChannelContext);
  let id = 338;
  const { lipscoreData } = useLipscoreProductData({
    id: 'service_review'
  });

  if (selectedChannel.id === 6) {
    id = 440;
  }
  return (
    <Query query={UspWrapperTopBarQuery} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading || error) return null;
        return (
          <UspInnerWrapper>
            <Text content={data.category.content} />
            <LipscoreProductRating
              rating={lipscoreData?.rating}
              identifier={lipscoreData?.internal_id}
              reviewCount={lipscoreData?.review_count}
              text={{
                rating: lipscoreData?.rating
              }}
            />
          </UspInnerWrapper>
        );
      }}
    </Query>
  );
};

export default UspWrapperTopBar;
