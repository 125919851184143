import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
library.add(faAngleDown);

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 50;
  top: 2.5rem;
  right: 0;
  width: 100%;
`;

const ChannelSelectorButton = styled('button')`
  background: ${theme('colors.black')};
  color: ${theme('colors.white')};
  font-size: 11px;
  letter-spacing: 1.4px;
  line-height: 1.5;
  outline: none;
  text-transform: uppercase;
  ${theme('below.lg')} {
    background: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    margin: 0 5px 0 0;
    img {
      margin: 0 5px 1px 0;
    }
  }
  svg {
    height: 11px;
    width: 5.5px;
    ${theme('below.lg')} {
      height: 20px;
      width: 10px;
      color: black;
      margin: 0;
      padding: 0;
    }
  }
`;

const CountryFlag = styled('img')`
  height: 12px;
  margin-right: 0.5rem;
  margin-bottom: 2px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  display: inline-block;
  padding-top: 2px;
  margin-left: 5px;
  width: 20px;
  background: transparent;
`;

export default class HeaderChannelSelector extends Component {
  render() {
    const { mobile } = this.props;
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => (
          <Fragment>
            <Above breakpoint="lg">
              <FlyoutTrigger id="channel-selector">
                {({ showTarget, hideTarget, isOpen }) => (
                  <ChannelSelectorButton
                    onClick={isOpen ? hideTarget : showTarget}
                  >
                    <CountryFlag
                      src={`https://flagcdn.com/h20/${selectedChannel.country.code.toLowerCase()}.png`}
                      alt="Country Flag"
                    />
                    {selectedChannel.country.name}
                    <ArrowIcon icon={['fal', 'angle-down']} />
                  </ChannelSelectorButton>
                )}
              </FlyoutTrigger>
            </Above>
            <Below breakpoint="lg">
              <DrawerTrigger id="mobile-channel-selector">
                {drawer => (
                  <ChannelSelectorButton
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <CountryFlag
                      src={`https://flagcdn.com/h20/${selectedChannel.country.code.toLowerCase()}.png`}
                      alt="Country Flag"
                    />
                    {!mobile && selectedChannel.country.name}
                    <ArrowIcon icon={['fal', 'angle-down']} />
                  </ChannelSelectorButton>
                )}
              </DrawerTrigger>
            </Below>
            <FlyoutTarget id="channel-selector">
              {({ isOpen, hideTarget }) => (
                <Flyout>
                  <Selector
                    channels={channels}
                    hideTarget={hideTarget}
                    selectedChannel={selectedChannel}
                    updateChannel={updateChannel}
                  />
                </Flyout>
              )}
            </FlyoutTarget>
          </Fragment>
        )}
      </ChannelContext.Consumer>
    );
  }
}
