import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import ChannelSelector from '@jetshop/ui/ChannelSelector/ChannelSelector';
import debounce from 'lodash.debounce';
import React, { PureComponent } from 'react';
import styled, { css } from 'react-emotion';
import MaxWidth from '../../MaxWidth';
import flattenCountries from './flattenCountries';

//import CloseButton from '../ui/CloseButton';
//import { ReactComponent as Arrow } from '../../svg/Arrow.svg';

const ChannelColumns = styled('div')`
  flex: 1 1 auto;
  ${props => props.border && 'border-right: 1px solid rgb(231, 228, 228);'}
`;

const CountryContainer = styled('div')`
  padding: 0 0.5rem;
  ${({ theme }) => theme.below.md} {
    padding: 0;
    border-bottom: 1px solid #e2e1df;
  }
`;

const Country = styled('button')`
  border: 0;
  background: none;
  width: 100%;
  box-shadow: none;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 2rem;
  font-size: 0.9rem;
  padding: 0;
  padding-left: 1rem;
  ${({ theme }) => theme.below.md} {
    padding: 0.5rem 1rem;
  }
  cursor: pointer;
  -webkit-column-break-inside: avoid;
  img {
    height: 1rem;
    padding-right: 0.5rem;
  }
  &:hover {
    font-weight: bold;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: 1px solid black;
  }
  ${props =>
    props.isSelected &&
    css`
      font-weight: bold;
    `};

  ${props =>
    props.isOpen &&
    css`
      font-weight: bold;
    `};
`;

const SelectorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.below.md} {
    flex-direction: column-reverse;
  }
`;

const CombinationWrapper = styled('div')`
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const Combination = styled('button')`
  border: 0;
  line-height: 2rem;
  cursor: pointer;

  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.5rem;

  background: none;
  border: 0;
  appearance: none;

  ${({ theme }) => theme.below.md} {
    padding-left: 0.2rem;
  }
  svg {
    display: none;
    margin: 0;
    padding: 0;
    margin-right: 0.5rem;
    width: 12px;
    height: 12px;
    ${({ theme }) => theme.below.md} {
      display: flex;
    }
  }

  &:hover {
    text-decoration: underline;
    svg {
      display: flex;
    }
  }

  &:active {
    ${({ theme }) => theme.below.md} {
      font-weight: ${({ theme }) => theme.fontWeights.semibold};
    }
  }
`;

const Currency = styled('span')`
  color: ${({ theme }) => theme.colors.grey};
  margin-left: 0.25rem;
`;

const ChannelWrapper = styled('div')`
  display: flex;
  ${({ theme }) => theme.below.md} {
    flex-direction: column;
  }
  padding-bottom: 2rem;
`;

const MicroCopy = styled('h2')`
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 2rem;
  color: #181818;
  ${({ theme }) => theme.below.md} {
    font-size: 1.2rem;
    line-height: 1.2;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  ${({ theme }) => theme.below.md} {
    padding: 0;
  }
`;

class Selector extends PureComponent {
  // There is no good way to evenly split items in a list over columns using
  // CSS, if those items will change height based on user interaction. CSS
  // columns will work, but as soon as the user expands one of the accordions,
  // the height of that item's column changes, causing the last item in the
  // column to spill over in to the next column, which looks janky and makes the
  // container's height jump around.

  // The only real solution is to chunk the items in to arrays and map over
  // them, creating some number of equally-sized columns. We also have to detect
  // the screen width so we know how many items to put in each column, in order
  // to create more or less columns. (5 columns fit nicely on a wider screen,
  // but 4 fit better on a narrow screen)

  state = {
    /** The original flattened array of channels, before chunking */
    originalChannels: [],
    /** a multi-dimensional array of channels chunked evenly */
    chunks: [],
    /** Whether or not the original array has been chunked */
    chunked: false,
    width: 0,
    /**
     * Whether or not the component is mounted. Used to ensure no resize events
     * are called when it's not mounted
     */
    _mounted: false
  };

  breakpoints = [
    { width: 0, chunks: 0 },
    { width: 1, chunks: 99 },
    { width: 600, chunks: 38 },
    { width: 1000, chunks: 15 },
    { width: 1250, chunks: 13 },
    { width: 1500, chunks: 13 },
    { width: 999999, chunks: 19 }
  ];

  handleSubmit = selectedChannel => {
    this.props.updateChannel(selectedChannel);
    this.props.hideTarget();
  };

  chunkChannels = (flattenedChannels, chunkSize) => {
    // Reduce the channels in to arrays of `chunkSize`
    const chunks = flattenedChannels.reduce(
      (memo, value, index) => {
        if (index % chunkSize === 0 && index !== 0) memo.push([]);
        memo[memo.length - 1].push(value);
        return memo;
      },
      [[]]
    );

    this.setState({ chunks, chunked: true });
  };

  onResize = () => {
    if (this.state._mounted) {
      this.setChunks();
    }
  };

  setChunks = () => {
    const currentWidth = window.innerWidth;

    // Map over our handcrafted breakpoints and chunk categories in to arrays
    // based on the `chunks` value when the window width matches
    this.breakpoints.forEach((bp, index) => {
      if (
        currentWidth > bp.width &&
        currentWidth <
          (this.breakpoints[index + 1] || bp[this.breakpoints.length - 1]).width
      ) {
        if (this.state.width === bp.width) return null;
        this.chunkChannels(this.state.originalChannels, bp.chunks);
        this.setState({ width: bp.width });
      }
    });
  };

  componentDidMount() {
    this.setState({ _mounted: true });
    this.initChannels(this.props.channels);
    this.listener = debounce(this.onResize, 150);
    window.addEventListener('resize', this.listener);
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.listener);
  };

  initChannels = channels => {
    const originalChannels = channels ? flattenCountries(channels) : [];

    // Flatten the channels and set the chunked flag to indicate that the state
    // has initialised
    this.setState(
      {
        originalChannels,
        chunked: true
      },
      this.setChunks
    );
  };

  render() {
    const { onSelect, selectedChannel, hideTarget } = this.props;
    return (
      <StyledMaxWidth>
        <SelectorWrapper>
          <div>
            <MicroCopy>{t('Select a country or region')}</MicroCopy>
            <ChannelWrapper>
              {this.state.chunks.map((chunk, index) => (
                <ChannelColumns
                  key={chunk[0].code}
                  border={index !== this.state.chunks.length - 1}
                >
                  <Accordion>
                    {({ handleClick, openIndexes, AccordionContent }) =>
                      chunk.map((c, index) => {
                        if (c.channel === 5 || c.channel === 6) {
                          return null;
                        }
                        return (
                          <CountryContainer key={c.code + index}>
                            <Country
                              isSelected={
                                c.code === selectedChannel.country.code
                              }
                              onClick={() => handleClick(index)}
                              isOpen={openIndexes.includes(index)}
                            >
                              <img
                                src={`https://flagcdn.com/h20/${c.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {c.name}
                            </Country>
                            <AccordionContent
                              isOpen={openIndexes.includes(index)}
                            >
                              <CombinationWrapper>
                                {c.languages.map((lang, langIndex) => {
                                  return c.currencies.map(curr => (
                                    <Combination
                                      key={curr.name + lang.culture}
                                      trailing={
                                        c.languages.length > 1 &&
                                        langIndex !== 0
                                      }
                                      tabIndex={
                                        openIndexes.includes(index) ? 0 : -1
                                      }
                                      open={openIndexes.includes(index)}
                                      onClick={() =>
                                        this.handleSubmit(
                                          onSelect(
                                            c.channel,
                                            lang.culture,
                                            curr.name,
                                            c.code
                                          )
                                        )
                                      }
                                    >
                                      {/* <Arrow /> */}
                                      {lang.name}{' '}
                                      <Currency>({curr.name})</Currency>
                                    </Combination>
                                  ));
                                })}
                              </CombinationWrapper>
                            </AccordionContent>
                          </CountryContainer>
                        );
                      })
                    }
                  </Accordion>
                </ChannelColumns>
              ))}
            </ChannelWrapper>
          </div>
        </SelectorWrapper>
      </StyledMaxWidth>
    );
  }
}

export default props => {
  return (
    <ChannelSelector
      channels={props.channels}
      initialChannel={props.selectedChannel}
      render={({ channels, onSelect, selectedChannel }) => {
        return (
          <Selector
            channels={channels}
            onSelect={onSelect}
            selectedChannel={selectedChannel}
            {...props}
          />
        );
      }}
    />
  );
};
