import { Link } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Above } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import ChannelSelector from './ChannelSelector/ChannelSelector';
import UspWrapperTopBar from './UspWrapperTopBar';

const Container = styled('div')`
  align-items: center;
  background: ${theme('colors.black')};
  color: ${theme('colors.white')};
  display: flex;
  font-family: ${theme('fonts.heading')};
  font-size: 11px;
  justify-content: space-between;
  letter-spacing: 1.4px;
  padding: 0 1rem;
  text-transform: uppercase;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 5px;

  &.outlet {
    background: ${theme('colors.outletRed')};
    ${theme('below.lg')} {
      li {
        font-size: 8px;
      }
    }
    button {
      background: ${theme('colors.outletRed')};
    }
  }

  a {
    transition: all linear 0.2s;
    &:hover {
      color: ${theme('colors.grey')};
      text-decoration: none;
    }
  }
  ${theme('below.lg')} {
    z-index: 2;
    padding-bottom: 3px;
    padding-top: 5px;
  }
  ${theme('below.md')} {
    padding: 5px 3px;
  }
`;

const CustomerService = styled('div')`
  a {
    color: inherit;
    text-decoration: none;
    margin-right: 5px;
  }
  ${theme('below.lg')} {
    display: none;
  }
`;

const Usp = styled('div')`
  ${theme('below.md')} {
    width: 100%;
  }
`;

const UspText = styled('div')`
  display: inline-block;
`;

function TopBar() {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <Container className={selectedChannel.id === 6 ? 'outlet' : 'not-outlet'}>
      <CustomerService>
        {/* <Link to="/kundtjanst">{t('Customer Service')}</Link> */}
        {selectedChannel.id === 6 ? (
          <a href="https://www.clnathletics.se/">clnathletics.se</a>
        ) : (
          <Link to="/cln-outlet">CLN Outlet</Link>
        )}
      </CustomerService>
      <Usp>
        {/* infoga checkmark (fal fa-check) som shortcode */}
        <UspWrapperTopBar />
      </Usp>
      {selectedChannel.id !== 6 ? (
        <Above breakpoint="md">
          <ChannelSelector />
        </Above>
      ) : (
        <div />
      )}
    </Container>
  );
}

export default TopBar;
