import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('section')`
  a {
    color: black !important;
    text-decoration: underline;
    padding: 0;
    &:hover {
      color: ${theme('colors.grey')};
    }
  }
`;

const ContactInfo = () => (
  <Wrapper>
    <h2>CLN ATHLETICS AB</h2>
    <p>
      Stämpelgatan 11
      <br />
      504 64 Borås
      <br />
      Sweden
      <br />
      <a href="mailto:info@clnathletics.com">info@clnathletics.com</a>
      <br />
    </p>
  </Wrapper>
);

export default ContactInfo;
