import React from 'react';
import styled from 'react-emotion';
import { theme } from '../../Theme';

const StarWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 20px 0;

  .rating-text {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    margin-top: 4px;

    span {
      text-decoration: underline;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
  p {
    display: none;
  }
  .start {
    stop-color: #fede00;
    stop-opacity: 1;
  }
  .end {
    stop-color: #bbbbbb;
    stop-opacity: 1;
  }
`;

const SVGStar = ({ startGradient, endGradient, index, identifier }) => {
  const whiteSpaceReplacer = string => {
    return string.replace(/\s/g, '-');
  };

  const id = whiteSpaceReplacer(identifier);
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id={`starFiller-${index}-${id}`}>
          <stop className="start" offset={`${startGradient}%`} />
          <stop className="end" offset={`${endGradient}%`} />
        </linearGradient>
      </defs>
      <path
        d="M17 6.1123L10.5063 6.11106L8.5 0L6.49373 6.11044L0 6.1092L5.25376 9.8877L3.24749 16L8.50063 12.2227L13.7525 16L11.7462 9.88956L17 6.1123Z"
        fill={`url(#starFiller-${index}-${id})`}
      />
    </svg>
  );
};

export const LipscoreProductRating = ({ rating, identifier, text }) => {
  if (!rating || rating < 1) return <div className="no-rating" />;

  const roundUp = (num, precision) => {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  };

  const loopTimes = roundUp(rating, 1);
  var starList = [];
  const fillPercent =
    loopTimes % 1 === 0 ? 100 : Math.round((loopTimes % 1) * 100);

  let i;
  for (i = 0; i < 5; i++) {
    let percent = i + 1 > loopTimes ? fillPercent : 100;
    if (i >= loopTimes) {
      percent = 0;
    }
    starList?.push(percent);
  }

  const handleClick = () => {
    document
      .getElementById('review-wrapper')
      .scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  return (
    <StarWrapper className="star-rating">
      <div className="stars">
        {starList?.map((star, index) => {
          return (
            <Star
              key={index}
              percent={star}
              index={index}
              identifier={identifier}
            />
          );
        })}
      </div>
      {text && (
        <div className="rating-text">
          {parseFloat(text?.rating).toFixed(1)} / 5
          <span style={{ textDecoration: 'none' }}> - </span>
          <span style={{ cursor: 'pointer' }} onClick={handleClick}>
            {/* {text?.review_count} omdömen */}
            {text?.votes} betyg
          </span>
        </div>
      )}
    </StarWrapper>
  );
};

const Star = ({ percent, index, identifier }) => {
  // Calculates the gradient for the star - no gradient and empty, half or full star
  const fillPercentage = percent < 25 ? 0 : percent < 75 ? 50 : 100;
  const startGradient = fillPercentage;
  const endGradient = fillPercentage;
  return (
    <SVGStar
      startGradient={startGradient}
      endGradient={endGradient}
      identifier={identifier}
      index={index}
    />
  );
};
