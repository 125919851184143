import React, { Component } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';

import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import NewsletterPopupQuery from './NewsletterPopupQuery.gql';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import NewsletterField from '../Cart/Newsletter/NewsletterField';

const NewsletterPopupWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
  display: flex;

  transition: 0.8s;
  animation: fade 0.8s;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const Cover = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
`;

const NewsletterPopupInner = styled('div')`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  max-width: 600px;
  background: ${props =>
    props.catImageUrl
      ? `url(${props.catImageUrl}) center center no-repeat #f7f7f7`
      : '#f7f7f7'};
  background-size: ${props => (props.catImage ? 'auto' : 'cover')};
  padding: 4rem 2rem;
  text-align: center;
  z-index: 2;
  ${theme('below.md')} {
    padding: 2.5rem 1.5rem;
  }

  a {
    display: block;
    color: #fff;
    background: black;
    text-decoration: none;
    padding: 15px 25px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 3px;
    font-family: ${theme('fonts.body')};
    font-weight: bold;
    transition: all, 0.2s ease;
    flex: none;
    outline: none;
    width: 100%;
    &:hover {
      background: #717171;
      color: white;
    }
  }
`;

const Text = styled('div')`
  margin-bottom: 2rem;

  ul {
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-top: 1rem;
    padding-left: 1rem;
    li {
      height: auto;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      ${theme('below.md')} {
        font-size: 12px;
      }
      &:before {
        content: '✓';
        padding-right: 0.25rem;
      }
    }
  }

  h2 {
    font-family: ${theme('fonts.cond')};
    font-size: 2rem;
    line-height: 1.2;
    ${theme('below.md')} {
      font-size: 1.5rem;
    }
  }

  p {
    line-height: 1;
    max-width: 400px;
    margin: auto;
    ${theme('below.md')} {
      font-size: 12px;
    }
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }
  b,
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
`;

const CloseButton = styled('button')`
  appearance: none;
  background: #fff;
  border-radius: 50%;
  font-weight: superbold;
  top: -1rem;
  outline: none;
  padding: 10px;
  position: fixed;
  right: -1rem;
  width: 45px;
  svg {
    height: 20px;
    width: 20px;
    * {
      fill: #000;
    }
  }
`;

class NewsletterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      if (typeof window.localStorage !== 'undefined') {
        if (!localStorage.getItem('newsletterPopup')) {
          this.setState({ visible: true });
          return true;
        }
      }
    }
  };

  handleClick = () => {
    this.setState({ visible: false });
    if (typeof window !== 'undefined') {
      if (typeof window.localStorage !== 'undefined') {
        localStorage.setItem(
          'newsletterPopup',
          JSON.stringify(this.state.visible)
        );
      }
    }
  };

  render() {
    const id = 415;

    if (!this.state.visible) {
      return null;
    }
    return (
      <ChannelContext.Consumer>
        {({ selectedChannel }) => (
          <>
            {selectedChannel && selectedChannel.id == '1' ? (
              <Query query={NewsletterPopupQuery} variables={{ id }}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error!: ${error}`;
                  return (
                    <NewsletterPopupWrapper>
                      <NewsletterPopupInner
                        catImage={
                          data.category.images.length < 1 ? true : false
                        }
                        catImageUrl={
                          data.category.images.length > 1
                            ? data.category.images[4].url
                            : null
                        }
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: data.category.content
                          }}
                        />
                        {/* <NewsletterField hideText={true} changeBtn={true} /> */}
                        <a
                          class="cta"
                          href="http://eepurl.com/drNpG1"
                          target="_blank"
                        >
                          {t('Sign up here')}
                        </a>
                        <CloseButton onClick={this.handleClick}>
                          <Cross />
                        </CloseButton>
                      </NewsletterPopupInner>
                      <Cover onClick={this.handleClick} />
                    </NewsletterPopupWrapper>
                  );
                }}
              </Query>
            ) : null}
          </>
        )}
      </ChannelContext.Consumer>
    );
  }
}

export default NewsletterPopup;
