import React from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';

const Wrapper = styled('div')`
  flex: 1 1 auto;
  border-left: 1px solid #e9e9e9;
  height: 100%;

  h2 {
    a {
      font-weight: bold;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
  }

  li {
    color: #8f8f8f;
    flex: auto;
    padding: 0;
    margin: 0;
    width: 100%;
    a {
      opacity: 1;
      color: #8f8f8f;
      padding: 0.3rem 1rem;
      display: block;
      font-size: 1rem;
      text-align: left;
      text-transform: none;
      letter-spacing: 0.5px;
      :hover,
      &.active {
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.8;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const Heading = styled('h2')``;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading.name}
        </CategoryLink>
      </Heading>
      <ul>
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
