import fetch from 'node-fetch';
import React, { useState, useEffect } from 'react';
import DepictProducts from './DepictProducts';

// Function for getting a session ID. Explained below in this document.
export function getSessionId() {
  var d;
  if (typeof window !== 'undefined') {
    return (
      (d = localStorage)._dep_id ||
      (d._dep_id = (2e17 * Math.random()).toString(36))
    );
  }
}

export const getRecommendationsRowWithMultiple = async (
  productId,
  type,
  productIds
) => {
  return fetch('https://api.depict.ai/v2/recommend/products/products', {
    method: 'POST',
    body: JSON.stringify({
      tenant: 'clnathletics',
      market: 'se',
      type: type,
      product_ids: productIds,
      session_id: getSessionId()
      // user_id: getUserId() // optional
    })
  }).then(res => res.json());
};

export const getRecommendationsRow = async (productId, type) => {
  return fetch('https://api.depict.ai/v2/recommend/products/general', {
    method: 'POST',
    body: JSON.stringify({
      tenant: 'clnathletics',
      market: 'se',
      type: type,
      session_id: getSessionId()
      // user_id: getUserId() // optional
    })
  }).then(res => res.json());
};

export const getRecommendationsRowForProduct = async (productId, type) => {
  return fetch('https://api.depict.ai/v2/recommend/products/product', {
    method: 'POST',
    body: JSON.stringify({
      tenant: 'clnathletics',
      market: 'se',
      type: type,
      product_id: productId,
      session_id: getSessionId()
      // user_id: getUserId() // optional
    })
  }).then(res => res.json());
};

const DepictProductRow = ({
  title,
  type,
  productId,
  productIds,
  smallProducts = false,
  mobileStyling = false,
  onClickCallback = () => {}
}) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      let func = () => {};
      if (productIds) {
        func = getRecommendationsRowWithMultiple;
      } else if (productId) {
        func = getRecommendationsRowForProduct;
      } else {
        func = getRecommendationsRow;
      }
      const data = await func(productId, type, productIds);
      setProducts(data?.displays);
    };

    fetchResults();
  }, []);

  const productsArray = products?.map(
    product => product.variant_displays[product.variant_index].product_id
  );
  const productObject = products?.map(product => {
    return {
      articleNumber: product.variant_displays[product.variant_index].product_id,
      recommendation_id: product.recommendation_id
    };
  });
  return (
    <DepictProducts
      productObject={productObject}
      products={productsArray}
      title={title}
      type={type}
      smallProducts={smallProducts}
      mobileStyling={mobileStyling}
      onClickCallback={onClickCallback}
    />
  );
};

export default DepictProductRow;
