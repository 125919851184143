import USEot from './UnitedSansReg-Medium.eot';
import USWoff from './UnitedSansReg-Medium.woff';
import USWoff2 from './UnitedSansReg-Medium.woff2';
import USTtf from './UnitedSansReg-Medium.ttf';
import USEotBold from './UnitedSansReg-Heavy.eot';
import USWoffBold from './UnitedSansReg-Heavy.woff';
import USWoff2Bold from './UnitedSansReg-Heavy.woff2';
import USTtfBold from './UnitedSansReg-Heavy.ttf';
import USEotLight from './UnitedSansReg-Light.eot';
import USWoffLight from './UnitedSansReg-Light.woff';
import USWoff2Light from './UnitedSansReg-Light.woff2';
import USTtfLight from './UnitedSansReg-Light.ttf';
import { injectGlobal } from 'emotion';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return injectGlobal`
  /* Regular font */
  @font-face {
    font-family: 'UnitedSansReg';
    src: url(${USEot}); /* IE9 Compat Mode */
    src: local('UnitedSansReg Regular'), local('SourceSansPro-Regular'),
        url(${USWoff2}) format('woff2'),
        url(${USWoff}) format('woff'),
        url(${USTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Bold font */
  @font-face {
    font-family: 'UnitedSansReg';
    src: url(${USEotBold}); /* IE9 Compat Mode */
    src: local('UnitedSansReg Bold'), local('SourceSansPro-Bold'),
        url(${USWoff2Bold}) format('woff2'),
        url(${USWoffBold}) format('woff'),
        url(${USTtfBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* Bold font */
  @font-face {
    font-family: 'UnitedSansReg';
    src: url(${USEotLight}); /* IE9 Compat Mode */
    src: local('UnitedSansReg Light'), local('SourceSansPro-Light'),
        url(${USWoff2Light}) format('woff2'),
        url(${USWoffLight}) format('woff'),
        url(${USTtfLight}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
`;
}
