import * as React from 'react';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import styled from 'react-emotion';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import t from '@jetshop/intl';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';

import InlineGridProduct from './InlineGridProduct';

const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;
const SearchFieldWrapper = styled('div')`
  width: 12rem;
  position: relative;
  font-size: 16px;
  font-weight: normal;
`;
const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  border: 1px solid #e8e8e8;
  width: 100%;
  top: 2rem;
  left: 0;
`;
const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const ShowAllResults = styled('button')`
  display: block;
  background: #181818;
  color: white;
  height: 60px;
  line-height: 60px;
  text-align: center;
  appearance: none;
  border: 0;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  transition: all, 0.2s ease;
  &:hover {
    background: #3a3a3a;
  }
`;

const SearchResults = ({
  triggerSearch,
  toggleSearch,
  term,
  result,
  loading,
  onClick
}) => {
  return result.products.result.length > 0 ? (
    <ul
      style={
        loading
          ? {
              opacity: 0.5
            }
          : null
      }
    >
      {result.products.result.map((item, index) => (
        <React.Fragment key={index}>
          {index > 5 ? null : (
            <InlineGridProduct
              term={term}
              onClick={toggleSearch}
              key={item.id}
              product={item}
            />
          )}
        </React.Fragment>
      ))}
      <li>
        <ShowAllResults onClick={() => triggerSearch()}>
          {t('Show all results for "{term}"', {
            term
          })}
        </ShowAllResults>
      </li>
    </ul>
  ) : (
    !loading && (
      <div
        style={{
          padding: '1rem'
        }}
      >
        {t('No matches for "{term}"', {
          term
        })}
      </div>
    )
  );
};

const SearchField = _a => {
  var {
      initialValue,
      className,
      placeholder = '',
      autocompleteQuery,
      toggleSearch
    } = _a,
    rest = __rest(_a, [
      'initialValue',
      'className',
      'placeholder',
      'autocompleteQuery'
    ]);

  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );
  return (
    <SearchAutoCompleteContainer
      {...Object.assign(
        {
          autocompleteQuery: autocompleteQuery,
          initialValue: initialValue
        },
        rest
      )}
    >
      {searchField => (
        <Wrapper className={className}>
          <SearchFieldWrapper>
            <FlyoutTrigger showCover={false} id="searchFlyout">
              {({ showTarget, hideTarget }) => (
                <React.Fragment>
                  <input
                    {...Object.assign(
                      {
                        placeholder: placeholder
                      },
                      searchField.getInputProps({
                        onFocus: showTarget
                      })
                    )}
                  />
                  <CancelButton
                    {...Object.assign(
                      {},
                      searchField.getCancelProps({
                        onClick: hideTarget
                      })
                    )}
                  >
                    {t('Cancel')}
                  </CancelButton>
                </React.Fragment>
              )}
            </FlyoutTrigger>
            <FlyoutTarget id="searchFlyout">
              {flyout =>
                searchField.isDirty &&
                !(searchField.loading && searchField.result?.length === 0) &&
                searchField.isOpen &&
                flyout.isOpen ? (
                  <Flyout>
                    <div {...Object.assign({}, searchField.getFlyoutProps())}>
                      {searchField.isDirty && searchField.result ? (
                        <SearchResults
                          triggerSearch={searchField.triggerSearch}
                          toggleSearch={toggleSearch}
                          term={searchField.term}
                          result={searchField.result}
                          onClick={flyout.hideTarget}
                          loading={searchField.loading}
                        />
                      ) : null}
                    </div>
                  </Flyout>
                ) : null
              }
            </FlyoutTarget>
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;
SearchField.Cancel = CancelButton;
export default SearchField;

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};

    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];

    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(p[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]];
      }
    return t;
  };
