import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import cartQuery from './CartQuery.gql';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';
library.add(faShoppingBag);

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: ${theme('colors.black')};
  border: 0;
  outline: none;
  position: relative;
  padding: 10px;
  padding-right: 2rem;
  padding-top: 15px;
  div {
    position: relative;
    display: flex;
    ${theme('below.lg')} {
      display: flex;
      svg {
        display: block;
      }
    }
  }
  ${theme('below.lg')} {
    padding: 0;
    padding: 10px 5px;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }

  .Toastify__toast {
    position: fixed;
    transform: none !important;
    opacity: 1 !important;
    background: rgba(255, 255, 255, 0.8);
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  width: 18px;
`;

const Badge = styled('span')`
  background: ${theme('colors.black')};
  color: white;
  font-size: 9px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: -5px;
  right: -8px;
  font-weight: 300;
`;

class CartButton extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <ToastContainerWrapper>
          <StyledToastContainer
            toastClassName={css`
              padding: 0px;
              cursor: auto;
              font-family: inherit;
            `}
            autoClose={false}
            hideProgressBar
            closeButton={false}
            closeOnClick={false}
          />
        </ToastContainerWrapper>
        <CartProvider query={cartQuery}>
          {result => {
            // Set items in cart to the API result.
            // If the result is undefined, fall back to 0
            const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

            return (
              // Use a flyout for breakpoints above md
              // Else use a Drawer
              <DrawerTrigger preventOverflow={true} id="cart-drawer">
                {drawer => (
                  <Button
                    className="cart-button"
                    data-testid="header-cart"
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <div>
                      <HeaderIcon icon={['fal', 'shopping-bag']} />
                      {itemsInCart > 0 && (
                        <Badge className="badge">{itemsInCart}</Badge>
                      )}
                    </div>
                  </Button>
                )}
              </DrawerTrigger>
            );
          }}
        </CartProvider>
      </Fragment>
    );
  }
}

export default CartButton;
