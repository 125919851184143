import React, { Fragment } from 'react';
import styled from 'react-emotion';

import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';

import FavoriteItem from './FavoriteItem';
import { FavoriteListConsumer } from './FavoriteListProvider';
import CartFlyout, { Flyout } from '../Cart/CartFlyout';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faShoppingBag,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons';
library.add(faHeart, faShoppingBag, faTrashAlt);

const FavoriteItemList = styled('div')`
  overflow-y: scroll;
  max-height: calc(100% - 11.5rem);
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
  ${theme('below.md')} {
    max-height: calc(100% - 3rem);
  }
`;

export const StyledFavoritesButton = styled('div')`
  width: 50%;
  position: relative;
  &.active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background: ${theme('colors.warmGrey')};
    }
  }
  button {
    width: 100%;
    text-align: center;
    padding: 0 20px;
    border-top: 1px solid ${theme('colors.warmGrey')};
    border-bottom: 1px solid ${theme('colors.warmGrey')};
    border-left: 1px solid ${theme('colors.warmGrey')};
    height: 70px;
    svg {
      font-size: 1.5rem;
    }
  }
`;

const EmptyButton = styled('div')`
  color: #a5a5a5;
  cursor: pointer;
  margin: 1rem 0;
  text-align: right;
  display: flex;
  align-items: bottom;
  justify-content: center;
  span {
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 5px;
    margin-top: 2px;
    use,
    g {
      fill: #a5a5a5;
    }
  }
`;

export const FavoritesFlyoutOuter = styled('div')`
  > div {
    height: 100%;
    overflow: hidden;
    ${theme('above.md')} {
      margin-top: 89px;
    }
    ${theme('below.lg')} {
      margin-top: 77px;
      height: calc(100% - 50px);
      overflow: hidden;
    }
  }
`;

const FavoritesBottom = styled('div')`
  background: #fff;
  padding: 20px 10px;
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
`;

const NoFavorites = styled('p')`
  color: ${theme('colors.black')};
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.3;
  margin-top: calc(50vh - 108px - 3rem);
  padding: 0 1.5rem;
  text-align: center;
`;

const HeartIcon = styled(FontAwesomeIcon)`
  color: ${theme('colors.warmGrey')};
  display: block;
  font-size: 4rem;
  margin: auto;
  margin-bottom: 0.5rem;
`;

const Trashbin = styled(FontAwesomeIcon)`
  color: ${theme('colors.grey')};
  font-size: 12px;
  margin: auto;
`;

const FavoritesHeader = styled('h2')`
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 2rem;
`;

const FavoritesFlyoutView = ({ modal, ...rest }) => {
  return (
    <FavoriteListConsumer>
      {({ favoriteList, removeFromList }) => (
        <Flyout {...rest}>
          <FavoritesHeader>{t('Your favorites')}</FavoritesHeader>
          <FavoriteItemList>
            {favoriteList &&
              favoriteList.map((item, index) => (
                <FavoriteItem
                  key={index}
                  item={item}
                  removeFromList={removeFromList}
                  onClick={modal.hideTarget}
                />
              ))}
          </FavoriteItemList>
          {favoriteList.length > 0 ? (
            <FavoritesBottom>
              <EmptyButton>
                <span onClick={() => removeFromList(-1)}>
                  <Trashbin icon={['fal', 'trash-alt']} />{' '}
                  {t('Clear favorites')}
                </span>
              </EmptyButton>
            </FavoritesBottom>
          ) : (
            <NoFavorites>
              <HeartIcon icon={['fal', 'heart']} />
              {t(
                'Add products by clicking the heart next to the product image.'
              )}
            </NoFavorites>
          )}
        </Flyout>
      )}
    </FavoriteListConsumer>
  );
};

const FavoritesFlyout = props => (
  // Wrap the flyout with the needed providers
  <Fragment>
    <FavoritesFlyoutOuter className="open">
      <DrawerTarget id="favorites-drawer">
        {drawer => (
          <Drawer isOpen={drawer.isOpen} right>
            <FavoritesFlyoutView modal={drawer} {...props} />
          </Drawer>
        )}
      </DrawerTarget>
    </FavoritesFlyoutOuter>
  </Fragment>
);

export default FavoritesFlyout;
