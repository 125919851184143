import React, { Component } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import t from '@jetshop/intl';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import ProductLink from '@jetshop/ui/ProductLink';
import { Price } from '@jetshop/ui/Price';

const ProductWrapper = styled('div')`
  padding: 5px;
  border-bottom: 1px solid #ddd;
  transition: all, 0.2s ease;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-bottom: 0;
  &:hover {
    background: #f7f7f7;
  }
`;
const ProductInnerWrapper = styled('div')`
  height: 100%;
`;
const ProductBlockLink = styled(ProductLink)`
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none !important;
  }
  &:hover {
    p {
      text-decoration: underline;
    }
  }
`;
const ProductDetails = styled('div')`
  text-align: center;
  width: 100%;
`;
const Name = styled('p')`
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: 1.3;
  font-weight: 400;
  text-align: left;
  padding-right: 30px;
  text-transform: uppercase;
`;

const ProductImageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 5px;
  width: 25%;
  margin-right: 15px;
  &:before {
    // content: '';
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // background: rgba(0, 0, 0, 0.02);
    // z-index: 1;
    // pointer-events: none;
  }

  &.not-buyable {
    //background: rgba(255, 255, 255, 0.75);
    &:after {
      // content: '';
      // position: absolute;
      // top: 0;
      // right: 0;
      // left: 0;
      // bottom: 0;
      // background: rgba(255, 255, 255, 0.75);
      // z-index: 0;
      // pointer-events: none;
    }
  }
`;

const StyledPrice = styled(Price)`
  font-size: 0.85rem;
  font-weight: 300;
  display: flex;

  ${Price.New} {
    color: #000;
    margin-right: 5px;
  }
  ${Price.Old} {
    color: #949494;
  }
`;

const ProductDetailRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProductNameRow = styled('div')`
  display: block;
`;

const SubName = styled('span')`
  display: block;
  font-weight: 300;
  font-size: 12px;
  color: #757575;
  margin-bottom: 5px;
  line-height: 1.2;
  padding-right: 30px;
`;

const SoldOutBanner = styled('h3')`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  z-index: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  background: none;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0;
`;

const ProductStars = styled('div')``;

function getProductColor(array) {
  for (var i = 0; i < array.length; i++) {
    const element = array[i];
    if (element.key === 'displaycolor') {
      return element.stringValue;
    }
  }
}

class GridProduct extends Component {
  render() {
    const {
      product,
      categoryPath,
      loading,
      className = '',
      onClick
    } = this.props;

    return (
      <ProductWrapper data-testid="product" className={className}>
        <ProductInnerWrapper>
          <ProductBlockLink
            product={product}
            categoryPath={categoryPath}
            className="image-block-link"
            onClick={onClick}
          >
            {product.images[0] && (
              <ProductImageWrapper
                className={
                  product && product.stockStatus && !product.stockStatus.buyable
                    ? 'not-buyable'
                    : null
                }
              >
                {product &&
                product.stockStatus &&
                !product.stockStatus.buyable ? (
                  <SoldOutBanner>{t('Sold out')}</SoldOutBanner>
                ) : null}
                <ProductImage
                  sizes="400"
                  aspect={'1:1'}
                  image={product.images[0]}
                  badges={product.badges}
                />
              </ProductImageWrapper>
            )}
            <ProductDetails>
              <ProductNameRow>
                <Name>{product.name}</Name>
                <SubName>
                  {product.customFields &&
                    getProductColor(product.customFields)}
                </SubName>
              </ProductNameRow>
              <ProductDetailRow>
                {/* <SubName>{product.subName}</SubName> */}
                <StyledPrice
                  price={product.price}
                  recommendedPrice={product.recommendedPrice}
                  previousPrice={product.previousPrice}
                />
                <ProductStars></ProductStars>
              </ProductDetailRow>
            </ProductDetails>
          </ProductBlockLink>
        </ProductInnerWrapper>
      </ProductWrapper>
    );
  }
}
export default GridProduct;
