import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import styled, { cx } from 'react-emotion';
import { Price } from '../Price';
import { NewProductCard } from './NewProductCard';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  ${Price.Wrapper} {
    display: flex;
  }
  ${Price.New} {
    color: ${theme('colors.red')};
    margin-right: 8px;
  }
  ${Price.Old} {
    color: ${theme('colors.grey')};
  }
  .product-card {
    line-height: 19px;
    /* 
       * For browsers that do not support grid, we need to specify the width of
       * each product
       */
    width: 50%;
    ${({ theme }) => theme.above.md} {
      width: 33.333%;
    }
    ${({ theme }) => theme.above.lg} {
      width: 25%;
    }
    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    margin-bottom: 2em;
    .product-card {
      width: 100%;
      a {
        margin: 0;
      }
    }

    ${({ theme }) => theme.below.lg} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${({ theme }) => theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 5px;
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  cart,
  productObject,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  let groupedProducts = groupBy(
    products,
    product => product.articleNumber.split('-')[0]
  );
  groupedProducts = Array.from(groupedProducts, ([name, value]) => ({
    name,
    value
  }));

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        const variants = product?.variants?.values;
        const recommendation_id = productObject?.filter(depictProd =>
          variants?.find(
            variant => variant?.articleNumber === depictProd.articleNumber
          )
        );
        return (
          <NewProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            className="product-card"
            cart={cart}
            groupedProducts={groupedProducts}
            recommendation_id={recommendation_id}
            {...rest}
          />
        );
      })}
    </Wrapper>
  );
}
