import React from 'react';
import styled, { css } from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('section')`
  h2 {
    font-family: ${theme('fonts.body')};
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 10px;
  }
  p {
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }
`;

const NewsletterForm = styled('div')`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 0;
  }

  form,
  input {
    width: 100%;
  }
  form {
    display: ${props => (props.hideText ? 'block' : 'flex')};
  }

  input {
    background: white;
    border: none;
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    padding: ${props => (props.hideText ? '15px 25px' : '0 1rem')};
    letter-spacing: 1px;
    margin-bottom: ${props => (props.hideText ? '1rem' : '0')};
  }
  button {
    display: inline-block;
    color: #fff;
    background: black;
    text-decoration: none;
    padding: 15px 25px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 3px;
    font-family: ${theme('fonts.body')};
    font-weight: bold;
    transition: all, 0.2s ease;
    flex: none;
    margin-left: ${props => (props.hideText ? '0' : '10px')};
    outline: none;
    width: ${props => (props.hideText ? '100%' : 'auto')};
    &:hover {
      background: #717171;
      color: white;
    }
  }
`;

const Message = styled('div')`
  margin-bottom: ${props => (props.changeBtn ? '0.5rem' : '0')};
  margin-top: ${props => (props.changeBtn ? '0' : '0.5rem')};
`;

const Error = styled('div')`
  color: #650e1b;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <React.Fragment>
              <Wrapper>
                {!this.props.hideText && (
                  <>
                    <h2>{t('Sign up for our newsletter')}</h2>
                    <p>
                      {t(
                        'Get 10% off your first purchase, and enjoy or latest news and offers!'
                      )}
                    </p>
                  </>
                )}

                {!this.props.hideText && this.state.error ? (
                  <Message>
                    <Error>
                      {getErrorDetail(result.error).codes.includes(
                        'AlreadySubscribed'
                      )
                        ? t('You are already subscribed to the newsletter!')
                        : t(
                            'Something went wrong. Please check your email and try again.'
                          )}
                    </Error>
                  </Message>
                ) : null}
                {/* Show the completion success message once signed up, otherwise show the form */}
                <NewsletterForm
                  hideText={this.props.hideText ? true : false}
                  changeBtn={this.props.changeBtn ? true : false}
                >
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          type="email"
                          placeholder={t('Enter email address')}
                          onChange={this.updateEmail}
                          className={css`
                            padding-right: ${result.loading ? '8rem' : '4rem'};
                          `}
                        />
                      )}
                    </Intl>
                    <button>
                      {result.loading
                        ? t('Submitting…')
                        : !this.props.changeBtn
                        ? t('Submit') // Button text for newsletter pop-up
                        : t('Sign up here')}
                    </button>
                  </form>
                </NewsletterForm>
                {this.state.completed ? (
                  <Message>{t('You are now subscribed')}</Message>
                ) : (
                  !this.props.hideText && <Message>&nbsp;</Message>
                )}
              </Wrapper>
            </React.Fragment>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
