import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import SearchButton from './Search/SearchButton';
import SearchBar from './Search/SearchBar';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import styled from 'react-emotion';
import { ReactComponent as Logotype } from '../../../svg/Logo.svg';
import { ReactComponent as LogotypeOutlet } from '../../../svg/LogoOutlet.svg';
import theme from '@jetshop/ui/utils/theme';
import FavoritesButton from '../../FavoriteList/FavoritesButton';
import FavoritesFlyout from '../../FavoriteList/FavoritesFlyout';
import TopBar from './TopBar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
library.add(faHeart);

const Outer = styled('div')`
  background: #fff;
  width: 100%;

  &.outlet {
    background: ${theme('colors.outletRedLight')};
    nav {
      background: ${theme('colors.outletRedLight')};
      > div > ul > li > a {
        color: white;
        &:hover {
          color: white;
        }
      }
    }
    button svg {
      color: white;
    }
    .badge {
      background: white;
      color: ${theme('colors.outletRed')};
    }
  }
`;
const Nav = styled('nav')`
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 80rem;
  margin: auto;
`;

const Home = styled('div')`
  width: 9rem;
  padding-left: 2rem;
  a {
    display: block;
    max-width: 90px;
    &.outlet {
      max-width: 110px;
    }
  }
  svg.regular {
    * {
      fill: ${theme('colors.black')};
    }
  }
`;

const HeaderIconWrapper = styled('div')`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: flex-end;
  margin-left: auto;
  width: 9rem;
  align-items: end;
  button {
    padding: 7px !important;
    margin: 0 !important;
    &.cart-button {
      padding-right: 2rem !important;
    }
  }
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  width: 18px;
`;

function MainMenu({ categories }) {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <>
      <TopBar />
      <Outer className={selectedChannel.id === 6 ? 'outlet' : 'not-outlet'}>
        <Nav>
          {selectedChannel.id === 6 ? (
            <Home>
              <Link to="/" className="outlet">
                <LogotypeOutlet className="outlet" />
              </Link>
            </Home>
          ) : (
            <Home>
              <Link to="/" className="regular">
                <Logotype className="regular" />
              </Link>
            </Home>
          )}

          <MenuContainer queryData={categories.data} />

          <HeaderIconWrapper>
            <div>
              <FavoritesButton>
                <HeaderIcon icon={['fal', 'heart']} />
              </FavoritesButton>
            </div>
            <div>
              <SearchButton
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                style={{ outline: 'none' }}
              />
            </div>
            <div>
              <CartButton />
            </div>
          </HeaderIconWrapper>

          <SearchBar setSearchOpen={setSearchOpen} searchOpen={searchOpen} />
        </Nav>
      </Outer>

      <FavoritesFlyout />
      <CartFlyout />
    </>
  );
}

export default MainMenu;
