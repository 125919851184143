import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import FooterQuery from './components/Layout/Footer/FooterQuery.gql';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'clnathletics',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      },
      {
        query: FooterQuery,
        variables: { id: 222 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  additionalGtagTrackingIds: ['AW-961882933'],
  relewareEnabled: false,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: false,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  deprecations: { useOldProductVariants: true, useOldFilterAPI: false }
};
