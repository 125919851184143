import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import theme from '@jetshop/ui/utils/theme';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import get from 'lodash.get';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import UIButton from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faShoppingBag,
  faBox,
  faTruck
} from '@fortawesome/pro-light-svg-icons';
import DepictProductRow from '../Depict/DepictProductRow';
library.add(faHeart, faShoppingBag, faBox, faTruck);

export const Flyout = styled('div')`
  color: ${theme('colors.black')};
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
  width: 50rem;
  min-width: 19rem;
  max-width: 100%;
  height: calc(100% - 5.6rem);
  overflow-y: scroll;

  ${theme('below.lg')} {
    top: 0;
    height: calc(100% - 5.4rem);
  }
`;

export const CartButtons = styled('div')`
  display: flex;
  button {
    background: none;
    transition: all, 0.2s ease;
    &:hover {
      background: #f4f4f4;
    }
  }
`;
export const CartTopButton = styled('div')`
  width: 50%;
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  &:first-child {
    border-left: 0;
  }
  &.active {
    border-bottom: 3px solid #181818;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const CartHeader = styled('h2')`
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 2rem;
`;

const CartItems = styled('section')`
  padding: 0;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Summary = styled('section')`
  background: #fff;
  padding: 20px 10px;
  /* position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0; */
  letter-spacing: 0.5px;
  text-transform: uppercase;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;

export const TotalSummary = styled('div')`
  font-size: 1.25rem;
  color: ${theme('colors.black')};
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  label {
    font-weight: 300;
  }
  > div {
    font-weight: 500;
  }
`;

export const Checkout = styled(UIButton)`
  background-color: ${theme('colors.black')};
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  padding: 1rem 0.5rem;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  width: 100%;
  font-family: ${theme('fonts.body')};
  letter-spacing: 3px;
  font-weight: bold;
`.withComponent('a');

export const UspContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

export const UspItem = styled('div')`
  display: flex;
  align-items: center;
  color: #a2a2a2;
  padding: 0 20px;
`;

export const UspIcon = styled(FontAwesomeIcon)`
  font-size: 0.7rem;
  margin: 0 3px;
`;

export const UspText = styled('span')`
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 300;
`;

export const CartFlyoutOuter = styled('div')`
  > div {
    overflow: hidden;
    ${theme('above.md')} {
      margin-top: 89px;
    }
    ${theme('below.lg')} {
      margin-top: 77px;
      height: calc(100% + 0.5rem);
      overflow: hidden;
    }
  }
`;

const Button = styled(UIButton)`
  background-color: ${theme('colors.warmGrey')};
  color: ${theme('colors.black')};
  font-family: ${theme('fonts.heading')};
  font-size: 12px;
  margin-top: 0.5rem;
  outline: none;
  padding: 1rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
  transition: all ease 0.3s;
  width: 100%;
  font-family: ${theme('fonts.body')};
  letter-spacing: 1px;
  font-weight: bold;
  &:hover {
    background-color: ${theme('colors.warmGreyAccent')};
  }
`;

const VatDetails = styled('div')`
  color: ${theme('colors.grey')};
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 2rem;
  text-align: center;
`;

const RecomsWrap = styled('div')`
  h2 {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
  .slider {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    > .product-card {
      flex: none;
      width: 60%;
    }
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = get(result, 'data.cart.items', []);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');
  const track = useTracker();
  const { selectedChannel } = useContext(ChannelContext);

  const VAT = new Intl.NumberFormat(selectedChannel.defaultLanguage.culture, {
    style: 'currency',
    currency: selectedChannel.currency.name
  }).format(result.data.cart.productTotal.vat);

  const articleNumbers = items.map(item => item.articleNumber);

  return (
    <Flyout {...rest}>
      <CartHeader>{t('Your shopping cart')}</CartHeader>
      <CartItems>
        {items.map(item => (
          <CartItem hideTarget={modal.hideTarget} item={item} key={item.id} />
        ))}
      </CartItems>

      <Summary>
        <TotalSummary>
          <label>{t('Total')}:&nbsp;</label>
          <Price price={result.data.cart.productTotal} />
        </TotalSummary>
        <VatDetails>
          <label>{t('of which VAT')}:&nbsp;</label>
          {VAT}
        </VatDetails>

        {checkoutUrl && (
          <Checkout
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
        <Button onClick={modal.hideTarget}>{t('Continue shopping')}</Button>
      </Summary>
      <DepictProductRow
        type={'checkout'}
        title={'Du kanske också gillar'}
        productIds={articleNumbers}
        mobileStyling={true}
        onClickCallback={modal.hideTarget}
      />
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartFlyoutOuter>
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <Drawer isOpen={drawer.isOpen} right>
                <CartFlyoutView modal={drawer} result={result} {...props} />
              </Drawer>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  </CartFlyoutOuter>
);

export default CartFlyout;
