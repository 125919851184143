import React from 'react';
import styled from 'react-emotion';

import t from '@jetshop/intl';

import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
library.add(faFacebookF, faInstagram, faYoutube);

const Wrapper = styled('section')`
  ${theme('below.lg')} {
    margin-bottom: 30px;
  }
  a {
    background: black;
    color: white !important;
    font-size: 2rem;
    margin: 0 0.5rem;
    text-align: center;
    transition: all ease 0.3s;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${theme('colors.blackAccent')};
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
  }
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  > svg {
    height: inherit;
  }
`;

const SocialLinks = () => (
  <Wrapper>
    <h2>{t('Follow us')}</h2>
    <div>
      <a href="https://www.facebook.com/clnathletics/?fref=ts">
        <HeaderIcon icon={['fab', 'facebook-f']} />
      </a>
      <a href="https://www.instagram.com/clnathletics/">
        <HeaderIcon icon={['fab', 'instagram']} />
      </a>
      <a href="https://www.youtube.com/channel/UCEIzdEUAlmPIim4piNMA4eA">
        <HeaderIcon icon={['fab', 'youtube']} />
      </a>
    </div>
  </Wrapper>
);

export default SocialLinks;
