import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useEffect, useContext } from 'react';
import styled from 'react-emotion';
import { useScript } from '../../../hooks/useScript';

const ProductRatingWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;

  .lipscore-rating-small {
    .lipscore-rating-star-inactive {
      fill: #bbbbbb !important;
    }
    .lipscore-svg-rating-star {
      fill: #f3df27;
    }
    .lipscore-rating-star {
      height: 12px;
      width: 12px;
    }
  }
`;

export const LipscoreProductSmallRating = ({
  product,
  onCard,
  handleClick
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  const apiKey = '82fee2e9231259a98259b093';
  const language = selectedChannel?.country?.code?.toLowerCase();
  const currency = selectedChannel?.currency?.name;
  const brand = product?.subName;
  const path = product?.primaryRoute?.path;
  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loaded) {
        if (window.lipscore) {
          if (!window.lipscore.isInitialized()) {
            window.lipscoreInit = () => {
              window.lipscore.init({
                apiKey: apiKey
              });
            };
            window.lipscore.apiKey = apiKey;
          }
          window.lipscore.initWidgets();
        }
      }
    }
  }, [loaded]);

  if (!loaded || error) return null;

  return (
    <ProductRatingWrapper>
      {onCard ? (
        <div
          className="lipscore-rating-small"
          ls-product-name={product?.name}
          ls-brand={brand}
          ls-product-id={product?.id}
          ls-product-url={selectedChannel?.url + path}
          ls-description={product.shortDescription}
          ls-price={product?.price?.incVat}
          ls-price-currency={currency}
        />
      ) : (
        <div
          id="lipscore-rating"
          ls-product-name={product?.name}
          ls-brand={brand}
          ls-product-id={product?.id}
          ls-product-url={selectedChannel?.url + path}
          ls-description={product.shortDescription}
          ls-price={product?.price?.incVat}
          ls-price-currency={currency}
          onClick={() => handleClick('3')}
        />
      )}
    </ProductRatingWrapper>
  );
};
