import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Headers } from 'node-fetch';
import { useEffect, useState, useContext } from 'react';

export const useLipscoreProductData = (product, fields) => {
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.language?.culture
    ?.split('-')?.[1]
    ?.toLowerCase();
  const [lipscoreData, setLipscoreData] = useState();

  useEffect(() => {
    const secret = 'd7e76c7e5d29d7892e6b4506708c1489';
    const apiKey = '82fee2e9231259a98259b093';
    const productId = product?.id;
    // const productId = 'service_review';
    const standardFields = fields ?? [
      'rating',
      'review_count',
      'reviews',
      'votes'
    ];
    const fieldsString = standardFields?.join(',');
    const url = `https://api.lipscore.com/products/?internal_id[]=${productId}&fields=${fieldsString}&api_key=${apiKey}`;

    const httpsHeaders = new Headers();
    httpsHeaders.append('X-Authorization', secret);

    const options = {
      method: 'GET',
      redirect: 'follow',
      headers: httpsHeaders
    };
    fetch(url, options)
      .then(response => {
        // check response
        return response?.json();
      })
      .then(data => {
        setLipscoreData(data?.[0]);
      });
  }, [fields, language, product?.id]);

  return { lipscoreData };
};
