import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch, useHistory } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import FavoriteListProvider from './FavoriteList/FavoriteListProvider';
import NewsletterPopup from './NewsletterPopup/NewsletterPopup';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { fonts } from './Theme';
import CampaignBar from './CampaignBar/CampaignBar';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

const TriggerbeeTop = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      <div
        id="triggerbee-placeholder-startpage-top"
        className={location?.key}
      />;
    });
  }, [history]);

  return <div id="triggerbee-placeholder-startpage-top" />;
};

function Shop() {
  const { channels, selectedChannel } = useContext(ChannelContext);
  let contentId = '';
  switch (selectedChannel.id) {
    case 1:
      contentId = 'x43okdlsxxvnpxm0l4x8kih81qxxw0';
      break;
    case 3:
      contentId = 'a2r6hh1yhhp85k49d0fa2fundau4ha';
      break;
    default:
      contentId = '';
      break;
  }

  useEffect(() => {
    import('@depict-ai/dpc').then(({ DPC }) => {
      const dpc = new DPC('clnathletics', { market: 'se' });
    });
  }, []);

  return (
    <GenericError>
      <ModalProvider>
        <FavoriteListProvider>
          <Container>
            <LoadingBar />
            <CustomFont primaryFont={fonts.primary} injectCss={loadFontCss} />
            <Helmet
              titleTemplate="%s - CLN Athletics"
              defaultTitle="CLN Athletics"
            >
              <meta name="facebook-domain-verification" content={contentId} />
            </Helmet>
            {/* <NewsletterPopup /> */}
            <Header />
            <Content>
              <CampaignBar />
              <TriggerbeeTop />
              <PaginationProvider defaultProductsPerPage={80}>
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/search" component={LoadableSearchPage} />
                  <Route path="/signup" component={LoadableSignUpPage} />
                  <Route path="/login" component={LogInPage} />
                  <Route path="/stores" component={StoreLocator} />
                  <Route path="/store/:id" component={Store} />
                  <Route path="/tree" component={NavTreePage} />
                  <Route path="/my-pages" component={LoadableMyPages} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Footer />
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
          </Container>
        </FavoriteListProvider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
