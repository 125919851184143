import React from 'react';
import styled from 'react-emotion';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { Above } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';

import { FavoriteListConsumer } from './FavoriteListProvider';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons';
library.add(faShoppingCart, faHeart);

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: ${theme('colors.black')};
  border: 0;
  outline: none;
  transition: all, 0.2s;
  padding-top: 5px;
  div {
    position: relative;
    display: flex;
    ${theme('below.lg')} {
      display: flex;
      svg {
        display: block;
      }
    }
  }
  ${theme('below.lg')} {
    padding: 10px 5px;
  }
`;

const Holder = styled('div')`
  position: relative;
  display: flex;
`;

const Badge = styled('span')`
  background: ${theme('colors.black')};
  color: white;
  font-size: 9px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: -5px;
  right: -8px;
  font-weight: 300;
`;

const BadgeNum = () => (
  <FavoriteListConsumer>
    {({ favoriteList }) => <Badge>{favoriteList.length}</Badge>}
  </FavoriteListConsumer>
);

const FavoritesButton = props => (
  <FavoriteListConsumer>
    {({ favoriteList }) => (
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Holder>
              <DrawerTrigger id="favorites-drawer">
                {drawer => (
                  <Button
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <div>
                      {props.children}
                      {favoriteList.length > 0 && <BadgeNum />}
                    </div>
                  </Button>
                )}
              </DrawerTrigger>
            </Holder>
          ) : (
            <React.Fragment>
              <DrawerTrigger id="favorites-drawer">
                {drawer => (
                  <Button
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <div>
                      {props.children}
                      {favoriteList.length > 0 && <BadgeNum />}
                    </div>
                  </Button>
                )}
              </DrawerTrigger>
            </React.Fragment>
          )
        }
      </Above>
    )}
  </FavoriteListConsumer>
);

export default FavoritesButton;
