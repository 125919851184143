import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
// import theme from '@jetshop/ui/utils/theme';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  padding: 0 !important;
  display: block;
  max-width: 1020px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2rem 0 !important;
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')``;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
  padding: 20px 0;
  background: #f7f7f7;
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  z-index: 10;
  height: 0;
  top: 100%;

  white-space: nowrap;
  min-width: 200px;
  width: 300px;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  margin-top: -2px;

  left: 0;
  right: 0;
  transform: none;
  width: 100%;
  max-width: 100%;

  a {
    display: block !important;
    padding: 5px 1rem !important;
  }
`;

const NoThirdTierItems = styled('div')`
  h2 {
  }
`;
// const Triangle = styled('span')`
//   position: absolute;
//   top: -20px;
//   left: 0;
//   right: 0;
//   margin: auto;

//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 5px 5px 0 5px;
//   border-color: #000000 transparent transparent transparent;
// `;

const NoSubMenuItems = styled('div')`
  border-left: 1px solid #e9e9e9;
  height: 100%;
  a {
    font-weight: bold;
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  const withSubcategories = activeCategory?.subcategories?.filter(
    cat => cat.subcategories.length > 0
  );
  const withoutSubcategories = activeCategory?.subcategories?.filter(
    cat => cat.subcategories.length === 0
  );

  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper>
        <ContentFit>
          <MaxWidth>
            {/* <Triangle /> */}
            {withSubcategories &&
              withSubcategories.map(cat => (
                <SubMenuSection
                  key={cat.id}
                  heading={cat}
                  categories={cat.subcategories}
                  onSelect={closeNav}
                />
              ))}
            {withoutSubcategories && (
              <NoSubMenuItems>
                {withoutSubcategories.map(cat => (
                  <NoThirdTierItems key={cat.id}>
                    <h2>
                      <CategoryLink category={cat} onClick={closeNav}>
                        {cat.name}
                      </CategoryLink>
                    </h2>
                  </NoThirdTierItems>
                ))}
              </NoSubMenuItems>
            )}
          </MaxWidth>
        </ContentFit>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
