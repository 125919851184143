import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../svg/angle-right.svg';

const Scroll = styled('div')`
  flex: 1 1 auto;
  position: relative;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 99999;
  max-width: 100%;
  border-top: 1px solid #ddd;

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;
const MenuWrapper = styled('div')`
  background: #ebebeb;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  padding-bottom: 6rem;
  button,
  a {
    &.extra-cat {
      background: #ebebeb;
      border-color: #d1d1d1;
      text-transform: uppercase;
      font-weight: bold;
    }
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 14px;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 12px 20px;
    text-align: left;
    border-bottom: 1px solid #dedede;
    color: #333333;
    text-decoration: none;
    span {
      display: flex;
      align-items: center;
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    svg {
      transform: rotate(-180deg);
      margin-right: 0.5rem;
    }
  }
`;

const MenuButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    flex: 1;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: white;
    appearance: none;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    &:first-child {
      border-right: 1px solid #ddd;
    }
    &.active {
      border-bottom: 2px solid #000;
    }
  }
`;

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  extraCategories,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };
  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen]);

  return (
    <>
      <MenuWrapper active={active}>
        {parent && (
          <button onClick={goBack} className="backbutton">
            <span>
              <Caret />
              {parent.parent
                ? `Tillbaka till ${parent.parent.name}`
                : 'Tillbaka'}
            </span>
          </button>
        )}
        {parent && (
          <Link onClick={rest.close} to={parent.primaryRoute.path}>
            <span>
              <strong>Se alla</strong>
            </span>
            <Caret />
          </Link>
        )}
        {categories.map(cat =>
          cat.hasSubcategories && cat?.subcategories ? (
            <button key={`btn-${cat.id}`} onClick={() => setActiveId(cat.id)}>
              <span>{cat.name}</span>
              <Caret />
            </button>
          ) : (
            <Link
              onClick={rest.close}
              to={cat.primaryRoute.path}
              key={`link-${cat.id}`}
            >
              <span>{cat.name}</span>
              <Caret />
            </Link>
          )
        )}
        {extraCategories?.map(cat =>
          cat.hasSubcategories && cat?.subcategories ? (
            <button
              className="extra-cat"
              key={`btn-${cat.id}`}
              onClick={() => setActiveId(cat.id)}
            >
              <span>{cat.name}</span>
              <Caret />
            </button>
          ) : (
            <Link
              className="extra-cat"
              onClick={rest.close}
              to={cat.primaryRoute.path}
              key={`link-${cat.id}`}
            >
              <span>{cat.name}</span>
              <Caret />
            </Link>
          )
        )}
        <Link to="/kundtjanst" onClick={rest.close} className="extra-cat">
          <span>{t('Customer Service')}</span>
          <Caret />
        </Link>
      </MenuWrapper>
      {categories?.map(cat =>
        cat.hasSubcategories && cat?.subcategories ? (
          <MenuLevel
            key={`cat-${cat.id}`}
            {...rest}
            parent={{ ...cat, parent: parent }}
            goBack={backHandler}
            categories={cat.subcategories}
            active={cat.id === activeId}
          />
        ) : null
      )}
      {extraCategories?.map(cat =>
        cat.hasSubcategories && cat?.subcategories ? (
          <MenuLevel
            key={`cat-${cat.id}`}
            {...rest}
            parent={{ ...cat, parent: parent }}
            goBack={backHandler}
            categories={cat.subcategories}
            active={cat.id === activeId}
          />
        ) : null
      )}
    </>
  );
};

export const PositionedDrawer = ({
  isOpen,
  size = 320,
  children,
  belowHeader = false,
  left
}) => {
  const topPosition = useMemo(() => {
    let topPos = 0;
    if (!belowHeader) return topPos;

    if (typeof document !== 'undefined') {
      const topBarHeight = document.getElementById('topBar')?.clientHeight ?? 0;
      topPos += document.getElementById('stickyHeader')?.clientHeight ?? 0;
      topPos += topBarHeight;
      // if (window.pageYOffset < topBarHeight) {
      //   topPos -= window.pageYOffset;
      // } else if (window.pageYOffset > topBarHeight) {
      //   topPos -= topBarHeight;
      // }
    }
    return topPos;
  }, [isOpen]);

  return (
    <NewDrawer
      isOpen={isOpen}
      size={size}
      topPosition={topPosition}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({
  data,
  isSticky,
  size = 320,
  belowHeader = false,
  left = false
}) {
  if (!(data && data.categories)) return null;
  const [activeId, setActiveId] = useState(352);
  let menuButtonsArray = [
    data.categories.find(cat => cat.id === 352),
    data.categories.find(cat => cat.id === 369)
  ];
  let filteredCategories = [
    data.categories.filter(cat => cat.id !== 352 && cat.id !== 369)
  ];
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <PositionedDrawer
          isOpen={drawer.isOpen}
          size={size}
          belowHeader={belowHeader}
          left={left}
        >
          <MenuButtons>
            {menuButtonsArray.map((cat, index) => (
              <button
                key={index}
                onClick={() => setActiveId(cat.id)}
                className={cat.id === activeId ? 'active' : ''}
              >
                {cat.name}
              </button>
            ))}
          </MenuButtons>
          <Scroll>
            <MenuLevel
              isOpen={drawer.isOpen}
              close={drawer.hideTarget}
              categories={
                menuButtonsArray.find(cat => cat.id === activeId).subcategories
              }
              extraCategories={filteredCategories[0]}
              active={true}
            />
          </Scroll>
        </PositionedDrawer>
      )}
    </DrawerTarget>
  );
}
