import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import CampaignBarQuery from './CampaignBarQuery.gql';
import { ReactComponent as AngleRight } from '../../svg/angle-right.svg';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 13px;
    padding: 3px 0;
    font-weight: bold;
    ${theme('below.md')} {
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 10px 0;
    }
    svg {
      margin-left: 6px;
      color: inherit;
      height: 8px;
      path {
        fill: currentColor;
      }
    }
  }
`;

const CampaignBar = ({ id = 701 }) => {
  const { data, loading, error } = useQuery(CampaignBarQuery, {
    variables: { id: id }
  });
  if (loading || error) return null;
  if (!data || !data.category || !data.category.content) return null;

  let parsedContent;
  try {
    parsedContent = JSON.parse(data.category.content);
  } catch (e) {
    console.error('Failed to parse campaign content:', e);
    return null;
  }

  const { link, text, bgColor, textColor, hide } = parsedContent;

  if (hide) return null;

  return (
    <Wrapper style={{ backgroundColor: bgColor, color: textColor }}>
      <Link to={link} style={{ color: textColor }}>
        {text} <AngleRight />
      </Link>
    </Wrapper>
  );
};

export default CampaignBar;
