import React from 'react';
import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';

const TrendButtonStyle = ({ secondary, theme }) => css`
  background-color: #2f80ed;
  border-color: #2f80ed;
  color: white;
  font-size: 16px;
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  ${theme.above.md} {
    max-width: 100%;
  }

  :disabled {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  ${secondary &&
    css`
      background-color: white;
      border: 1px solid ${theme.colors.darkgrey};
      color: ${theme.colors.darkgrey};
    `};
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button {...props}>
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
