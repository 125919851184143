import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'react-emotion';
import SearchField from './CustomSearchField';
import t, { Intl } from '@jetshop/intl';
import AutocompleteQuery from '../AutocompleteQuery.gql';
import theme from '@jetshop/ui/utils/theme';

const PosedSearchBarContainer = styled(
  posed('div')({
    enter: {
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)`
  position: absolute;
  width: auto;
  top: 90px;
  right: 0;
  ${({ theme }) => theme.below.lg} {
    top: 78px;
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    use {
      fill: ${theme('colors.black')};
      opacity: 0.5;
    }
  }
`;

const InnerSearchBar = styled('div')`
  position: relative;
  width: 30vw;
  margin: 0 auto;
  z-index: 11;
  ${({ theme }) => theme.below.lg} {
    width: 40vw;
  }
  ${({ theme }) => theme.below.lg} {
    width: 60vw;
  }
  ${({ theme }) => theme.below.sm} {
    width: 100vw;
  }
`;

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    display: flex;
    height: 50px;
    width: 100%;

    > div {
    }

    ${({ theme }) => theme.below.lg} {
      width: 100%;
      height: 3rem;
    }
    input {
      border: 0;
      background: #fff;
      height: 100%;
      padding: 0 1rem;
      width: 100%;
      font-size: 16px;
      border-bottom: 1px solid ${theme('colors.warmGrey')};
      &:focus {
        outline: none;
      }
    }
  }
  ${SearchField.Cancel} {
    position: absolute;
    left: 100%;
    height: 100%;
    min-width: 48px;
    width: fit-content;
    display: none;
  }
  ${SearchField.Flyout} {
    text-align: left;
    top: calc(100% + ((54px - 100%) / 2));
    border: 0;
    margin-top: -2px;
    ${({ theme }) => theme.below.lg} {
      margin-top: -3px;
    }
  }
`;

const Cover = styled('div')`
  position: fixed;
  top: 90px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
  ${({ theme }) => theme.below.lg} {
    top: 78px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 6rem;
  }
  ${({ theme }) => theme.below.lg} {
    top: 77px;
  }
`;

const SearchBar = ({ setSearchOpen, searchOpen }) => (
  <PoseGroup>
    {searchOpen ? (
      <PosedSearchBarContainer
        pose={searchOpen ? 'open' : 'closed'}
        key={'searchBarPosed'}
      >
        <InnerSearchBar>
          <Intl>
            {t => (
              <StyledSearchField
                setSearchOpen={setSearchOpen}
                searchOpen={searchOpen}
                placeholder={t('Search')}
                autocompleteQuery={AutocompleteQuery}
                onCancel={() => {
                  setSearchOpen(!searchOpen);
                }}
                onClick={() => {
                  setSearchOpen(!searchOpen);
                }}
                onSubmit={() => {
                  setSearchOpen(!searchOpen);
                }}
                focusOnLoad={true}
              />
            )}
          </Intl>
        </InnerSearchBar>
        <Cover onClick={() => setSearchOpen(!searchOpen)} />
      </PosedSearchBarContainer>
    ) : null}
  </PoseGroup>
);

export default SearchBar;
